<template>
  <div class="container">
    <nav-bar header-title="固定费用闸机" :show-back="true" :header-back="appBack" />
    <div class="content col-between-center">
      <p class="p1 f42 fw6">{{ ticketName }}</p>
      <p class="p2 BebasNeueBold f76"><span class="f36">¥</span>{{ ticketAmount }}</p>
      <div class="qr-box col-center-center">
        <img :src="`data:image/png;base64,${qr}`" alt="">
      </div>
      <p class="p3 f24">请指导用户打开超鹿运动APP＞首页右上角扫码<br>用户支付后需要确认下收款记录哦</p>
    </div>
    <div class="record">
      <p class="title f30 fw6">扫码记录</p>
      <div class="list">
        <div class="item row-between-center" v-for="item in records" :key="item.scanTime">
          <div class="left row-start-center">
            <img :src="item.headImg" alt="" class="avator">
            <div class="info">
              <p class="name f26 fw6">{{ `${item.nickName}（${item.mobilePhone}）` }}</p>
              <p class="time f24">{{ item.scanTime }}</p>
            </div>
          </div>
          <div class="right">
            <p class="status f28 fw6">已扫码</p>
          </div>
          <div :class="['mask', (item.isNew) ? 'ani' : '']"></div>
        </div>
      </div>
      <p class="tip" v-show="!records.length">暂无记录</p>
    </div>

  </div>
</template>

<script>
// import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar/nav-bar'
export default {
  // mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {

      searchParam: {
        venueId: '',
        doorId: '',
        lastRecordUtc: '',
        teacherId: '',
      },
      ticketName: '',
      ticketAmount: '',
      qr: '',
      records: [],

      timer: null
    }
  },
  computed: {},
  async mounted() {
    initBack()

    const { name, money, ...other } = getParam()

    this.ticketName = decodeURIComponent(name)
    this.ticketAmount = money
    this.searchParam = Object.assign(this.searchParam, other)

    this.getQr()

    this.getRecords()
    this.timer = setInterval(this.getRecords, 6000)
    // 监听切后台 切前台
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.timer = setInterval(this.getRecords, 6000)
      } else {
        clearInterval(this.timer)
      }
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    appBack: newAppBack,
    getQr() {
      this.$axios.post(`${this.baseURLApp}/swimSingleFee/showQrCode`, this.searchParam).then(res => {
        this.qr = res.data
      })
    },
    getRecords() {
      this.$axios.post(`${this.baseURLApp}/swimSingleFee/queryScanList`, this.searchParam).then(res => {
        this.records = res.data.concat(this.records).slice(0, 10)
        if (res.data.length) {
          this.searchParam.lastRecordUtc = res.data[0].scanUtc
          res.data.map((item, index) => {
            this.$set(this.records[index], 'isNew', true)
          })
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }

  padding: calc(100px + constant(safe-area-inset-top)) 0 0;
  padding: calc(100px + env(safe-area-inset-top)) 0 0;

  .content {
    width: 100%;
    height: 938px;
    padding: 30px 0 54px;
    background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/swimming/ewm-bg2.png);
    background-size: 100% 100%;
    text-align: center;

    .p1 {
      color: #242831;
    }

    .p2 {
      color: #267DFF;
    }

    .p3 {
      color: #232831;
    }

    .qr-box {
      width: 550px;
      height: 554px;
      background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/swimming/ewm-bg.png);
      background-size: 100% 100%;
      img {
        width: 410px;
      }
    }
  }

  .record {
    width: 100%;
    padding: 40px 32px;
    margin: -20px 0 0;
    background: #FFFFFF;
    border-radius: 40px 40px 0px 0px;

    .title {
      margin: 0 0 24px;
    }
    .tip {
      width: 100%;
      text-align: center;
      margin: 100px 0 0;
      color: grey;
      font-style: italic;
    }
    .item {
      min-height: 162px;
      border-bottom: 2px solid #F5F5F5;
      position: relative;

      .mask {
        width: 750px;
        height: 100%;
        position: absolute;
        left: -32px;
        top: 0;

        &.ani {
          animation: newEra 3s linear;
          animation-fill-mode: forwards;
        }
      }

      .avator {
        width: 84px;
        height: 84px;
        border-radius: 50%;
      }

      .info {
        margin: 0 0 0 20px;
        .time {
          color: #6C727A;
          margin: 8px 0 0;
        }
      }

      .status {
        color: #267DFF;
      }

    }
  }

  @keyframes newEra {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    25% {
      background-color: rgba(0, 0, 0, .3);
    }
    50% {
      background-color: rgba(0, 0, 0, .1);
    }
    75% {
      background-color: rgba(0, 0, 0, .3);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}
</style>
